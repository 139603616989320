import { Link, graphql } from "gatsby";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars
import tw from "tailwind.macro";
import Image from "gatsby-image";

import BlockContent from "@sanity/block-content-to-react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";

import Layout from "~layouts/main";
import SEO from "~utils/seo";
import PageTitle from "~components/PageTitle";
import PreFooter from "~components/PreFooter";
import BlockSection from "~components/BlockSection";

if (typeof window !== "undefined") {
}

export const query = graphql`
  query AboutPageQuery {
    page: sanityAbout {
      about: _rawAbout
    }
    members: allSanityMember {
      nodes {
        name
        role
        bio: _rawBio
        portrait {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

let MapSection = () => <section></section>;
// Prevent mapbox-gl from throwing errors
if (typeof window !== "undefined") {
  const Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1IjoiYWthc2hiaGF2ZSIsImEiOiJjazd1bGludWExMzF1M2ZvNGhvd245bjJhIn0.es1bwWUUrw-O6RwsGZEcpw",
    dragRotate: false,
    scrollZoom: false,
    minZoom: 8,
  });
  MapSection = () => (
    <section className="pt-16">
      <Map
        // eslint-disable-next-line react/style-prop-object
        style="mapbox://styles/akashbhave/ck7uvybip2k4f1in81h0adwr4"
        css={css`
          width: 100%;
          height: 400px;
          ${tw`border`}
        `}
        zoom={[9]}
        center={[-77.430678, 38.906491]}
      >
        <Layer
          type="circle"
          paint={{
            "circle-color": "#5EB1BF",
            "circle-radius": 60,
            "circle-opacity": 0.4,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#083C54",
          }}
          id="marker"
        >
          <Feature coordinates={[-77.430678, 38.906491]} draggable={false} />
        </Layer>
      </Map>
    </section>
  );
}

const AboutPage = ({ data }) => (
  <Layout>
    <SEO
      title="About | Paonix"
      description="Who are we? Paonix is based out of Northern Virginia and, to be honest, we're pretty small right now."
    />
    <PageTitle
      title="Who we are."
      subtitle="To be honest, we're pretty small right now."
    />
    <BlockSection invert={true}>
      <BlockContent blocks={data.page.about} />
    </BlockSection>
    <section className="p-16">
      <h2 className="text-3xl font-bold font-sans mb-8">Our Crew</h2>
      <div>
        {data.members.nodes.map((member, i) => (
          <div className="flex flex-wrap" key={i}>
            <Image
              css={css`
                filter: grayscale(80%);
                &:hover {
                  filter: grayscale(0%);
                }
              `}
              className="border flex-1 md:mr-8 mb-8 trans-all
                shadow-xl hover:shadow-2xl"
              fluid={member.portrait.asset.fluid}
            />
            <div className="flex flex-col md:w-3/4 w-full">
              <h3 className="font-sans font-bold text-2xl">{member.name}</h3>
              <h4 className="opacity-75 text-lg">{member.role}</h4>
              <BlockContent blocks={member.bio} />
            </div>
          </div>
        ))}
      </div>
    </section>
    <MapSection />
    <PreFooter title="Want to see what we've done?">
      <p>
        Navigate to the{" "}
        <Link className="button" to="/work">
          work
        </Link>{" "}
        page to see our past projects.
      </p>
    </PreFooter>
  </Layout>
);

export default AboutPage;
